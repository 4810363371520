import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, SxProps } from "@mui/material";
import { bgcolor } from "@mui/system";
import * as pdfjs from "pdfjs-dist";
import React, { useEffect, useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const PDFImage = ({ file, sx = {} }: { file: File; sx?: SxProps }) => {
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
  useEffect(() => {
    const renderPage = async () => {
      if (!file) return;

      const arrayBuffer = await file.arrayBuffer();

      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;

      const page = await pdf.getPage(1);

      const desiredWidth = 600;

      const viewport = page.getViewport({ scale: 1 });

      const scale = desiredWidth / viewport.width;

      const scaledViewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      await page.render({ canvasContext: context, viewport: scaledViewport })
        .promise;

      const dataUrl = canvas.toDataURL("image/png");

      setImageDataUrl(dataUrl);
    };

    renderPage();
  }, [file]);

  return (
    <Box sx={{ ...sx, overflow: "visible", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 0,
          transform: "translateX(50%)",
          p: 0.5,
          backgroundColor: "white",
          borderRadius: 1,
          boxShadow: "0 0 5px 1px rgba(0, 0, 0, 0.1)",
          lineHeight: 0,
        }}
      >
        <PictureAsPdfIcon sx={{ color: "red" }} />
      </Box>
      {imageDataUrl && (
        <Box
          component="img"
          src={imageDataUrl}
          alt="PDF first page"
          sx={{ width: "100%" }}
        />
      )}
    </Box>
  );
};

export default PDFImage;
