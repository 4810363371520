import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import PaymentProcessorImg from "@cospex/client/converter/img/payment-processor.svg";
import { useCustomization } from "@cospex/client/hooks/useCustomization";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";

import Logo from "./Logo";

const FooterContainer = styled(Box)`
  padding-top: 6rem;
  li {
    margin-bottom: 2rem;
  }
`;

export default function Footer() {
  const { t, i18n } = useTranslation();
  const { languages, companyInfoImage } = useCustomization();
  return (
    <Box sx={{ backgroundColor: "#07052d", pt: 8 }}>
      <Container
        sx={{
          ".MuiTypography-root": {
            color: "white",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
                gap: 2,
                mb: 4,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              <Logo />
              {__APP_NAME__}
            </Box>

            <Typography
              variant="body2"
              sx={{ maxWidth: 300, pb: "1rem", color: "grey.500" }}
            >
              {t("footer-description")}
            </Typography>
            <LanguageSelect
              options={languages}
              sx={{
                backgroundColor: "white",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ul>
              <li>
                <Link target="_blank" href="/cookies">
                  {t("footer-link-cookies")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/privacy">
                  {t("footer-link-privacy")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/terms">
                  {t("footer-link-terms")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/contact">
                  {t("footer-link-contact")}
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ul>
              <li>
                <Link target="_blank" href="/pricing">
                  {t("footer-link-pricing")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/refunds">
                  {t("footer-link-refunds")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/login">
                  {t("footer-link-login")}
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/unsubscribe">
                  {t("footer-link-unsubscribe")}
                </Link>
              </li>
            </ul>
          </Grid>
          <Box sx={{ mt: "2rem" }}>
            <Box
              component="img"
              sx={{
                maxWidth: 180,
                ...(i18n.language === "fr"
                  ? {
                      transform: "translateX(10px)",
                    }
                  : {
                      clipPath: "inset(0 calc(100% - 140px) 0 0)",
                      transform: "translateX(10px)",
                    }),
              }}
              src={PaymentProcessorImg}
            />
          </Box>
          <MarketingEmail />
        </Grid>
        <Divider light sx={{ mb: 1 }} />
        <FooterContainer
          sx={{
            display: {
              xs: "block",
              md: "flex",
            },
            alignItems: "center",
            textAlign: {
              xs: "center",
              md: "left",
            },
            justifyContent: {
              xs: "center",
              md: "space-between",
            },
            pb: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              py: 2,
            }}
          >
            Copyright {" © "}
            {new Date().getFullYear()} {__APP_NAME__}{" "}
          </Typography>
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: "300px",
              py: "10px",
            }}
            src={companyInfoImage}
          />
        </FooterContainer>
      </Container>
    </Box>
  );
}
