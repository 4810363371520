import CompressionIcon from "@cospex/client/converter/components/CompressionIcon";
import FileIcon from "@cospex/client/converter/components/FileIcon";
import { OPERATION } from "@cospex/client/converter/constants";
import {
  getAcceptedMimeTypes,
  getExtensionColor,
} from "@cospex/client/converter/helpers";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import CompressionIconSvg from "@cospex/client/converter/img/compression.svg";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Alert, Box, Portal, Stack, Typography } from "@mui/material";
import { a, useSpring } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

let isInstanceRendered = false;
interface IUploadInput {
  limit?: number;
  limitError?: string | null;
  type: ResponseFile["operation"]["type"];
  compressionStyle?: { target: string } | undefined;
  conversionStyle?: { from: string; to: string } | undefined;
}

const FILE_SIZE_LIMIT = 500_000_000; // 500MB limit

const UploadInput = ({
  limit = 5,
  limitError = null,
  type = OPERATION.CONVERSION,
  compressionStyle,
  conversionStyle,
}: IUploadInput) => {
  if (isInstanceRendered) {
    console.error(
      "Only one instance of UploadInput can be rendered at the moment."
    );
    return null;
  }

  if (compressionStyle && conversionStyle) {
    throw new Error(
      "Both compressionStyle and conversionStyle cannot be defined at the same time."
    );
  }

  if (compressionStyle && type === OPERATION.CONVERSION) {
    console.warn("compressionStyle is set but type is conversion.");
  }

  if (conversionStyle && type === OPERATION.COMPRESSION) {
    console.warn("conversionStyle is set but type is optimization.");
  }

  const { t } = useTranslation();
  const { startUpload } = useUploads();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [showFileLimitError, setFileLimitError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const uploadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      isInstanceRendered = false;
    };
  }, []);

  const handleFileUpload = (files: FileList) => {
    if (limit && files.length > limit) {
      setFileLimitError(true);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      startUpload(files[i], type);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    for (let i = 0; i < files!.length; i++) {
      if (files![i].size > FILE_SIZE_LIMIT) {
        setFileSizeError("upload-file-size-limit");
        return;
      }
    }
    if (files) handleFileUpload(files);
    e.target.value = "";
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const fileList = e.dataTransfer.files;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].size > FILE_SIZE_LIMIT) {
        // 500MB limit
        setFileSizeError("upload-file-size-limit");
        return;
      }
    }
    if (fileList.length > 0) {
      handleFileUpload(fileList);
      uploadRef.current!.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setOverlayVisible(false);
  };

  useEffect(() => {
    const showOverlay = (e: DragEvent) => {
      e.preventDefault();
      if (
        e.dataTransfer?.items &&
        e.dataTransfer.items.length > 0 &&
        e.dataTransfer.items[0].kind === "file"
      ) {
        setOverlayVisible(true);
      }
    };
    const hideOverlay = (e: DragEvent) => {
      e.preventDefault();
      setOverlayVisible(false);
    };

    document.addEventListener("dragover", showOverlay);
    document.addEventListener("dragleave", hideOverlay);
    document.addEventListener("drop", hideOverlay);

    return () => {
      document.removeEventListener("dragover", showOverlay);
      document.removeEventListener("dragleave", hideOverlay);
      document.removeEventListener("drop", hideOverlay);
    };
  }, []);

  const style = useSpring({
    display: isOverlayVisible ? "flex" : "none",
    opacity: isOverlayVisible ? 1 : 0,
  });

  return (
    <Box ref={uploadRef} id="uploadInput">
      <Portal container={document.body}>
        {isOverlayVisible && (
          <a.div
            onDrop={handleDrop}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              backgroundColor: "rgba(0,0,0,0.7)",
              justifyContent: "center",
              alignItems: "center",
              ...style,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <DriveFolderUploadIcon sx={{ fontSize: 70, color: "#fff" }} />
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("uploader-subtitle")}
              </Typography>
            </Box>
          </a.div>
        )}
      </Portal>
      <input
        type="file"
        accept={getAcceptedMimeTypes().join(",")}
        multiple
        style={{ display: "none" }}
        onChange={handleChange}
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        style={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#FAFAFC",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            p: {
              md: 4,
              xs: 2,
            },
            mb: 1,
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#F4F4F8",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {(() => {
              if (conversionStyle) {
                return (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        fontSize: 14,
                      }}
                    >
                      {t("uploader-subtitle")}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <FileIcon
                        color={getExtensionColor(conversionStyle.from)}
                        text={conversionStyle.from}
                      />
                      <KeyboardDoubleArrowRight sx={{ fontSize: 32 }} />
                      <FileIcon
                        color={getExtensionColor(conversionStyle.to)}
                        text={conversionStyle.to}
                      />
                    </Stack>
                  </>
                );
              } else if (compressionStyle) {
                const compressionText = compressionStyle.target;
                const compressionColor = getExtensionColor(compressionText);
                return (
                  <>
                    {compressionText === "" ? (
                      <Box
                        component="img"
                        sx={{ mb: 2, width: 73, height: 80 }}
                        src={CompressionIconSvg}
                      />
                    ) : (
                      <CompressionIcon
                        color={compressionColor}
                        text={compressionText}
                      />
                    )}
                    <Typography variant="h6" sx={{ fontSize: 14 }}>
                      {t("uploader-subtitle")}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {t("uploader-description")}
                    </Typography>
                  </>
                );
              } else {
                return (
                  <>
                    <DriveFolderUploadIcon
                      sx={{ fontSize: 50 }}
                      color="error"
                    />
                    <Typography variant="h6" sx={{ fontSize: 14 }}>
                      {t("uploader-subtitle")}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {t("uploader-description")}
                    </Typography>
                  </>
                );
              }
            })()}
          </Box>
        </Box>
      </label>
      {showFileLimitError && (
        <Box sx={{ py: 2, width: "100%" }}>
          <Alert severity="error" onClose={() => setFileLimitError(false)}>
            {limitError ? (
              limitError
            ) : (
              <>
                {t("upload-length-limit")} {limit}
              </>
            )}
          </Alert>
        </Box>
      )}
      {fileSizeError && (
        <Box sx={{ py: 2, width: "100%" }}>
          <Alert severity="error" onClose={() => setFileSizeError(null)}>
            {fileSizeError}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default UploadInput;
